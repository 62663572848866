<template lang="pug">
  v-app
    v-card.m-auto.mb-4(:class="mobileView ? 'mx-4' : ''" style="border-radius: 20px; padding: 2rem;")
      v-card-title
        h3 
          b {{ $t("menu.profile.text") }}
      v-card-text.d-flex.flex-column(style="text-align: center")
        img(
          class="profile-image"
          src="@/assets/images/icon/default-profile-pic.png"
        )
        b.mb-4 {{ $store.state.customer.name }}
        span.mb-4 {{ $store.state.customer.school }} - {{ $store.state.customer.district }}
        b Hadiah Dimenangi: 
        br 
        em {{ $store.getters.isWonPrize ? 'YA' : 'TIDAK'}}
        br
        template(v-if="!$store.getters.isWonPrize")
          b Bolen Main "Lucky Draw" Hari Ini: 
          br
          em {{ $store.getters.canLuckyDraw ? "TIDAK" : 'YA'}}
        br
        b Quiz Sudah Dilengkapkan: 
        br
        em {{ $store.getters.isQuizDone ? 'YA' : 'TIDAK'}}
      v-card-actions
        v-btn(
          color="primary"
          v-bind="size"
          @click="logout"
        )
          b {{ $t("auth.logout") }}
</template>

<script>
export default {
  computed: {
    size() {
      const size = { xs: "small", sm: "small", lg: "large", xl: "x-large" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    },
    mobileView() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    }
  },
  methods: {
    logout() {
      this.$store.commit("customer/delete");
      this.$router.push("/");
    }
  }
};
</script>

<style lang="sass" scoped>
.v-card
  h3, em
    color: $primary-color

  *
    margin: auto

.profile-image
  // display: block
  margin: 4rem auto
  // position: relative
  // align-items: center
  // justify-content: center
  width: 50%
  // height: 180px
  // text-align: center
  // border-radius: 50%
  // background: #fce6e6
  // padding: 32px
  // object-fit: contain
</style>
